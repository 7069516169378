

























// CORE
import { Component, Vue, Prop } from 'vue-property-decorator'

// COMPONENTS
import Card from '@/components/cards/Card.vue'

@Component({
  inheritAttrs: false,
  components: {
    Card,
  },
})
export default class CaptionCard extends Vue {
  @Prop({ default: null })
  private heading!: string

  @Prop({ default: 'mdi-information' })
  private icon!: string

  @Prop({ default: false })
  private isCloseble!: boolean

  @Prop({ default: false })
  private isMini!: boolean

  private isOpen = true

  private handleClose () {
    if (this.isCloseble) {
      this.$emit('close')
    }
  }
}
