// CORE
import Vue from 'vue'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

// STORE
import store from '@/store'
import {
  DashboardDriverCurrentCarIdGetRequest,
  DashboardDriverCarIdGetParams,
  DashboardDriverCarIdGetRequest,
  DashboardDriverResource,
  IWidgetSetting,
} from '@/store/types'

@Module({
  dynamic: true,
  name: 'driver-widgets',
  store,
  namespaced: true,
})
class DriverWidgets extends VuexModule {
  // ------------------------------------ CONSTS ----------------------------------------------=+o>

  // Изначальное положение виджетов (полный список)
  DEFAULT_WIDGET_SETTINGS: IWidgetSetting[] = [
    {
      title: 'Штрафы',
      isOn: true,
      componentName: 'PenaltiesWidget',
    },
    {
      title: 'Заявки',
      isOn: true,
      componentName: 'OrdersWidget',
    },
  ]

  // ------------------------------------ WIDGETS SETTINGS ----------------------------------------------=+o>

  widgetsSettings: IWidgetSetting[] = [
    {
      title: 'Заявки',
      isOn: true,
      componentName: 'OrdersWidget',
    },
    {
      title: 'Штрафы',
      isOn: true,
      componentName: 'PenaltiesWidget',
    },
  ]

  @Mutation
  setWidgetsSettings (
    payload: { data: IWidgetSetting[], entity: string },
    storage = true,
  ) {
    Vue.set(this, 'widgetsSettings', payload.data)

    if (storage && payload.entity) {
      // чтобы не записывать при чтении из локал сторейдж
      // Сохранили в локал сторейдж с пометкой роли и сущности
      localStorage.setItem(
        `widgetsSettings-driver-${payload.entity}`,
        JSON.stringify(this.widgetsSettings),
      )
    }
  }

  @Action
  syncWidgetsSettings (entity: string) {
    // проверяем наличие сохранённых параметров для данной роли и данной сущности
    if (
      JSON.parse(
        localStorage.getItem(`widgetsSettings-driver-${entity}`) as string,
      )
    ) {
      const data = JSON.parse(
        localStorage.getItem(`widgetsSettings-driver-${entity}`) as string,
      )

      // записываем из локал сторейдж в стор
      this.setWidgetsSettings({ data, entity }, false)
    } else {
      this.setWidgetsSettings({
        data: this.DEFAULT_WIDGET_SETTINGS,
        entity: entity,
      })
    }
  }

  widgets: DashboardDriverResource | null = null

  get penalties () {
    return this.widgets?.penalties
  }

  get orders () {
    return this.widgets?.requests
  }

  @Mutation
  setWidgets (payload: DashboardDriverResource) {
    this.widgets = payload
  }

  @Action({ rawError: true })
  async fetchWidgetsForASpecificMonth ({ carParkId, params }: { carParkId: number, params?: DashboardDriverCarIdGetParams }) {
    const { data } = await DashboardDriverCarIdGetRequest(carParkId, params)

    this.setWidgets(data)
  }

  @Action({ rawError: true })
  async fetchWidgetsActualData (payload: number) {
    const { data } = await DashboardDriverCurrentCarIdGetRequest(payload)

    this.setWidgets(data)
  }
}

const DriverWidgetsModule = getModule(DriverWidgets)

export default DriverWidgetsModule
